import {blog,minim,packaging,weather,alhuda, people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star, html, css3, javascript,bootstrap5,react,adobexd,photoshop,figma, tailwind  } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "tech",
    title: "Tech",
  },
  {
    id: "portfolio",
    title: "Portfolio",
  },
  // {
  //   id: "clients",
  //   title: "Clients",
  // },
];

export const portfolio = [
  {
    id: "portfolio-1",
    image: packaging,
    title: "Packaging Website",
    content:
      "Web design and devalopment project, including logo, branding, web Ui and Graphics",
      url:"https://www.paperbirdpackaging.com/"
  },
  {
    id: "portfolio-2",
    image: minim,
    title: "Kitchen Websites ",
    content:
    "Web design and devalopment project, including logo, branding, web Ui and Graphics",
    url:"https://www.minimkitchens.com/beta/"

  },
  {
    id: "portfolio-3",
    image: blog,
    title: "Blog Site",
    content:
      "First Porject built with React",
    url:"https://github.com/bilalamalik/blog-site-with-react"


  },
  {
    id: "portfolio-4",
    image: weather,
    title: "Weather App",
    content:
      "A Weather built with Javascript and Weather Api",
    url:"https://github.com/bilalamalik/javascript-weather-app"

  },
  {
    id: "portfolio-5",
    image: alhuda,
    title: "Charity Websites ",
    content:
      "A Charity website built with Wordpress ",
    url:"https://www.alhudarelief.com/"

  },
];

export const features = [
  {
    id: "feature-1",
    image: star,
    title: "Rewards",
    content:
      "The best credit cards offer some tantalizing combinations of promotions and prizes",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Balance Transfer",
    content:
      "A balance transfer credit card can save you a lot of money in interest charges.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];
export const tech = [
  {
    id: "tech-1",
    icon: html,
    title: "Html",
  },
  {
    id: "tech-2",
    icon: css3,
    title: "Css 3",
  },{
    id: "tech-3",
    icon: javascript,
    title: "Javascript",
  },{
    id: "tech-4",
    icon: bootstrap5,
    title: "Bootstrap5",
  },{
    id: "tech-5",
    icon: react,
    title: "React",
  },{
    id: "tech-6",
    icon: tailwind,
    title: "Tailwind",
  },{
    id: "tech-7",
    icon: figma,
    title: "Figma",
  },
  {
    id: "tech-8",
    icon: adobexd,
    title: "Adobe Xd",
  },{
    id: "tech-9",
    icon: photoshop,
    title: "Photoshop",
  },
  
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];



export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];