import { portfolio } from "../constants";



const Portfolio = () => {
    return ( 
        <section id="portfolio" className="flex flex-col md:flex-row mt-36 mb-24 justify-center">
            <div className="flex flex-1">
                <div>

                <h1 className="text-white text-[32px] text-center md:text-right uppercase md:pr-12">My Work Experience and Portfolio</h1>
                <p className=" text-[18px]  text-white text-center md:text-right md:pl-24 md:pr-12">In my past experiance was providing my services to IDC Canada as full time front-end Developer </p>
                </div>

            </div>
            <div className="flex flex-1">
                <div className="">
                  {/* portfolio display section */}
                    <div className="mx-auto grid  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 ">
                        { portfolio.map((portfolio, index)=> (
                            <div key={portfolio.id} class="rounded-xl bg-gray-900  p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 ">
                                <a href={portfolio.url} target="_blank">
                                        <h2 class="text-slate-500 text-center mb-3">{portfolio.title}</h2>
                                    <div class="relative flex items-end overflow-hidden rounded-xl">
                                        <img src={portfolio.image} alt={portfolio.title} />
                                    </div>
                                    <div class="mt-1 p-2">
                                        {/* <div class="mt-1 flex items-end justify-between">
                                            <p class="text-[12px] font-bold  text-white">Front-end Developer</p>
                                        </div> */}
                                        <p class="mt-1 text-[12px] text-slate-400">{portfolio.content}</p>
                                    </div>
                                </a>
                            </div>
                            
                        ))}
                        
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Portfolio;