import { tech } from "../constants";

// const Icons = ({icon, title, content, index}) => {
//     return ( 
//         <div>

//         </div>
//      );
// }

const Tech = () => {
    return ( 
        <section id="tech" className=" flex flex-col mt-24">
            <div className=" flex  flex-row justify-center ">
                <h1 className=" text-white text-[32px]  uppercase">Tech I worked with</h1>                
            </div>
            <div className=" flex flex-1 flex-row flex-wrap mt-3 justify-center ">
                {
                    tech.map(( tech, index) => (
                        // <div className="text-white flex " key={tech.index} > {tech.icon}</div>
                        <div className=" text-center mr-6 ml-6 mt-10">
                            <h2 className="text-white text-[12px] capitalize ">{tech.title}</h2>
                            <img src={tech.icon} className=" w-[42px] mt-3 inline-block"/>
                        </div>
                    ))
                    // features.map(( tech, index) => (
                    //     // <div className="text-white flex " key={tech.index} > {tech.icon}</div>
                    //     <Icons key={tech.id} {...tech} index={index} />
                    // ))
                }
            </div>
        </section>
     );
}
 
export default Tech;